<template>
  <div class="page">
    <p style="text-align: center">
      <img
        src="https://app.restoku.id/assets/images/brand-color.png"
        width="150"
      />
    </p>
    <h1>Kitchen Display System</h1>

    <form @submit.prevent="login()">
      <div class="form-group">
        <label>Email</label>
        <input type="email" v-model="payload.email" />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="password" v-model="payload.password" />
      </div>
      <button type="submit" :disabled="loading">
        {{ loading ? "Loading" : "LOGIN" }}
      </button>
    </form>
  </div>
</template>

<script>
import * as api from "../api";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      payload: {},
    };
  },
  methods: {
    login() {
      this.loading = true;
      api
        .post("/v4/login", this.payload)
        .then((r) => {
          this.loading = false;
          if (r.valid) {
            const paramLogin = {
              isLogin: true,
              toko: r.data.toko,
              user: r.data.user,
              hakAkses: r.data.hak_akses,
            };
            this.$store.commit("login/setData", paramLogin);
            location.href = "/";
          } else {
            alert("Login gagal");
          }
        })
        .catch((e) => {
          alert("Server error");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}
h1 {
  font-weight: 800;
  font-size: 26px;
  margin: 0;
  text-align: center;
}
form {
  margin-top: 40px;
  button {
    margin-top: 10px;
  }
}
</style>