const state = {
    data:{}
}

const mutations = {
    setData(state, data) {
        state.data = data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
}
