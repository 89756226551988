<template>
  <div class="home">
    <div class="navbar">
      <div @click="$router.back()">
        <img src="https://img.icons8.com/android/512/left.png" alt="" />
      </div>
      <div>Detail Pesanan {{ data.no_meja || data.no_nota }}</div>
    </div>

    <div class="loading" v-show="loadingData" style="padding: 60px 30px;">
      <p style="text-align: center; font-size: 14px">Loading data...</p>
    </div>
    <div class="body" v-show="!loadingData">
      <div class="status" v-show="data.status_dapur == 'APPROVE'">
        <p>
          <b>Pesanan Diterima</b>
        </p>
      </div>
      <div class="title">
        <table>
          <tr>
            <td>No Nota</td>
            <td>:</td>
            <td>{{ data.no_nota }}</td>
          </tr>
          <tr>
            <td>Waktu Pesanan</td>
            <td>:</td>
            <td>{{ moment(data.tanggal).format("DD/MM/YY HH:mm") }}</td>
          </tr>
        </table>
      </div>

      <div class="pesanan" v-for="(pesanan, key) in data.pesanan" :key="key">
        <p class="separator">
          <b>{{ pesanan.kategori }}</b>
        </p>
        <div class="card-body">
          <ul>
            <li
              v-for="(menu, indexDetail) in pesanan.detail"
              :key="indexDetail"
            >
              <div>
                <p>{{ menu.nama }}</p>
                <small v-show="menu.catatan">- {{ menu.catatan }}</small>
              </div>
              <div>{{ menu.jumlah }}</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="action">
        <button
          v-show="data.status_dapur == 'PENDING'"
          type="button"
          :disabled="loading"
          @click="terimaOrder()"
        >
          {{ loading ? "Loading..." : "Terima Order" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./../api";
import moment from "moment";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      loadingData: false,
      data: {},
      loading: false,
    };
  },
  methods: {
    moment,
    fetch() {
      this.loadingData = true;
      api
        .get(
          `/v4/penjualan?guid=${this.$route.params.id}&id_toko=${this.$store.state.login.data.toko.id}`
        )
        .then((r) => {
          this.loadingData = false;
          this.data = r.data;

          let kategori = [];
          this.data.detail.forEach((detail) => {
            const foundIndex = kategori.findIndex(
              (k) => k.kategori.toUpperCase() == detail.kategori.toUpperCase()
            );
            if (foundIndex < 0) {
              kategori.push({
                kategori: detail.kategori.toUpperCase(),
              });
            }
          });

          kategori.forEach((kat) => {
            kat.detail = this.data.detail.filter(
              (k) => k.kategori.toUpperCase() == kat.kategori.toUpperCase()
            );
          });

          this.data.pesanan = kategori;
        });
    },
    terimaOrder() {
      this.loading = true;
      api.post(`/v4/penjualan/update-dapur`, this.data).then((r) => {
        this.$database
          .ref("/toko/" + this.$store.state.login.data.toko.id)
          .set({
            last_updated: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: "APPROVE KITCHEN",
          });

        this.fetch();
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 60px;

  .navbar {
    display: grid;
    grid-template-columns: 40px 1fr;
    img {
      height: 20px;
    }
  }

  .body {
  }

  .status {
    padding: 10px 10px 0;
    p {
      text-align: center;
      font-size: 14px;
      margin: 0;
      b {
        color: green;
      }
    }
  }

  .title {
    padding: 20px;
    table {
      border-collapse: collapse;
      tr {
        td {
          font-size: 14px;
          padding: 4px 0;
          &:nth-child(2) {
            padding-left: 4px;
            width: 10px;
          }
        }
      }
    }
  }

  .nota {
    font-size: 14px;
    background: #2196f3;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 600;
    display: block;
    margin-bottom: 4px;
    width: fit-content;
  }

  .separator {
    background-color: #eee;
    padding: 10px 15px;
    margin: 0;
    font-size: 14px;
    text-align: center;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #ddd;

      > div {
        p {
          margin: 0;
          color: var(--darker);
          font-size: 14px;
        }
        small {
          font-size: 13px;
        }
        &:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 15px;
      }
    }
  }

  .action {
    padding: 0px 20px 20px 20px;
  }
}
</style>