<template>
  <div class="home">
    <div class="navbar">
      <div>MONITOR PESANAN</div>
    </div>

    <div class="body">
      <ul>
        <li v-for="(item, index) in data" :key="index" @click="to(item)">
          <div>
            <span>{{ item.no_nota }}</span>
            <p>
              <b>{{ item.no_meja }} {{ item.tamu ? " / " + item.tamu : "" }}</b>
            </p>
            <small>{{ item.text_pesanan }}</small>

            <small class="tgl">
              {{ moment(item.tanggal).format("HH:mm") }}
            </small>
          </div>
          <div>
            <button
              type="button"
              @click="terimaOrder(item)"
              :disabled="item.loading"
              v-show="item.status_dapur != 'APPROVE'"
            >
              Terima Order
            </button>
            <span v-show="item.status_dapur == 'APPROVE'" class="kitchen">
              Diterima
            </span>
          </div>
        </li>
      </ul>

      <div
        style="text-align: center; padding: 60px 30px"
        v-show="data.length <= 0 && !loading"
      >
        <p>Belum ada pesanan masuk</p>
      </div>

      <div
        style="text-align: center; padding: 60px 30px"
        v-show="loading"
      >
        <p style="font-size: 14px;">Loading data...</p>
      </div>
    </div>

    <div class="modal" v-show="modalOpen">
      <div class="modal-body">
        <h1 class="blink">PESANAN BARU DITERIMA</h1>
        <button type="button" @click="ok()">OK</button>
      </div>
    </div>
    <div class="overlay" v-show="modalOpen"></div>

    <audio id="notif">
      <source src="alert.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
import * as api from "./../api";
import moment from "moment";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      loading: false,
      firstLoad: true,
      audio: null,
      stop: false,
      modalOpen: false,
      data: [],
    };
  },
  methods: {
    moment,
    playAlert() {
      this.audio.play();
      if (!this.stop) {
        setTimeout(() => {
          this.playAlert();
        }, 2000);
      }
    },
    ok() {
      this.stop = true;

      this.audio.pause();
      this.modalOpen = false;
    },
    to(item) {
      setTimeout(() => {
        this.$router.push(`/detail/${item.guid}`);
      }, 150);
    },
    fetch() {
      this.loading = true;
      api
        .get(
          "/v4/penjualan?id_toko=" +
            this.$store.state.login.data.toko.id +
            "&tgl_awal=" +
            moment().format("YYYY-MM-DD") +
            "&tgl_akhir=" +
            moment().format("YYYY-MM-DD")
        )
        .then((r) => {
          this.loading = false;
          this.data = [];

          r.data.forEach((item) => {
            if (item.status != "SUDAH_BAYAR") {
              let pesanan = [];
              item.detail.forEach((menu) => {
                pesanan.push(menu.nama);
              });
              item.loading = false;
              item.text_pesanan = pesanan.join(", ");
              this.data.push(item);
            }
          });
        });
    },
    update(item, status) {
      const text =
        status == "SEDANG_DIPROSES" ? "PROSES PESANAN?" : "SELESAIKAN PESANAN?";
      if (confirm(text)) {
        item.status = status;
        api.post(`/v4/penjualan/update-status`, item).then((r) => {
          this.fetch();
        });
      }
    },
    terimaOrder(item) {
      item.loading = true;
      api.post(`/v4/penjualan/update-dapur`, item).then((r) => {
        this.$database
          .ref("/toko/" + this.$store.state.login.data.toko.id)
          .set({
            last_updated: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: "APPROVE KITCHEN",
          });
        this.fetch();
      });
    },
  },
  mounted() {
    this.fetch();

    this.audio = document.getElementById("notif");

    const starCountRef = this.$database.ref(
      "toko/" + this.$store.state.login.data.toko.id
    );
    starCountRef.on("value", (snapshot) => {
      const data = snapshot.val();

      if (!this.firstLoad) {
        this.fetch();

        if (data.status == "BELUM_DIPROSES") {
          this.stop = false;
          this.playAlert();

          this.modalOpen = true;
        }
      }

      this.firstLoad = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 60px;

  .body {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        padding: 15px;

        &:active {
          background: #eee;
        }

        span {
          font-size: 12px;
          background: #2196f3;
          color: #fff;
          padding: 4px 8px;
          border-radius: 4px;
          font-weight: 600;
          display: block;
          margin-bottom: 4px;
          width: fit-content;
          &.kitchen {
            background-color: #fff;
            color: green;
          }
        }
        p {
          margin: 0 0 5px 0;
        }

        small {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 200px;
          &.tgl {
            margin-top: 10px;
          }
        }

        > div {
          &:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }
  }

  .modal {
    text-align: center;

    h1 {
      font-size: 24px;
    }
  }
}
</style>