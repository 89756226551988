import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAzHpfkZks7e7HMohLMc9wbi9bG4-zScBU",
  authDomain: "restoku-152801.firebaseapp.com",
  databaseURL: "https://restoku-152801.firebaseio.com",
  projectId: "restoku-152801",
  storageBucket: "restoku-152801.appspot.com",
  messagingSenderId: "699184829319",
  appId: "1:699184829319:web:a5954355f87cd87a8db922",
  measurementId: "G-GEPZYXJJDR"
};

firebase.initializeApp(firebaseConfig);

Vue.prototype.$database = firebase.database();

if (!store.state.login.data.isLogin) {
  router.push('/login')
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
